"use client";

import { useMarker } from "@/hooks/use-marker";

export interface MarkerProps {
  project: string;
}

export function Marker({ project }: MarkerProps) {
  useMarker(project);
  return null;
}
