"use client";

import { AuthProvider } from "@/components/auth-provider";
import { PageProgressProvider } from "@/components/page-progress";
import { ToastProvider } from "@/components/toast-provider";
import type { SessionUser } from "@/lib/auth/util";
import type { Optional, Serialized } from "@/lib/util";
import { theme as proTheme } from "@chakra-ui/pro-theme";
import {
  ChakraProvider,
  type ColorModeProviderProps,
  theme as baseTheme,
  extendTheme,
} from "@chakra-ui/react";
import { Theme } from "@nest/db";
import { ReCaptchaProvider } from "next-recaptcha-v3";
import { useMemo } from "react";

const theme = extendTheme(proTheme, {
  config: {
    useSystemColorMode: false,
    initialColorMode: "dark",
  },
  colors: { ...baseTheme.colors, brand: baseTheme.colors.blue },
  fonts: {
    heading: "var(--font-spline-sans)",
    body: "var(--font-open-sans)",
  },
});

export function Providers({
  user = null,
  children,
}: {
  user?: Serialized<Optional<SessionUser>>;
  children: React.ReactNode;
}) {
  const colorModeManager = useMemo<ColorModeProviderProps["colorModeManager"]>(
    () => ({
      type: "cookie",
      get: () => (user?.theme === Theme.LIGHT ? "light" : "dark"),
      set: () => {},
    }),
    [user],
  );

  if (!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY) {
    throw new Error("Missing NEXT_PUBLIC_RECAPTCHA_SITE_KEY");
  }

  return (
    <AuthProvider user={user}>
      <ReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      >
        <ChakraProvider theme={theme} colorModeManager={colorModeManager}>
          <PageProgressProvider>
            <ToastProvider>{children}</ToastProvider>
          </PageProgressProvider>
        </ChakraProvider>
      </ReCaptchaProvider>
    </AuthProvider>
  );
}
