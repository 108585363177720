"use client";

import markerSDK from "@marker.io/browser";
import { useEffect } from "react";

export function useMarker(project: string) {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const widget = markerSDK.loadWidget({
        project,
      });

      return () => {
        widget.then((w) => w.unload());
      };
    }
  }, [project]);
}
